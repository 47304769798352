import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import { useNavigate } from 'react-router-dom'
import { REDIRECT_URL, diSearchLogo } from '../../utils/constants'
import allPaths from '../../Config/paths.jsx'
import Button from '@mui/material/Button'

const Sidenav = (props) => {
    const navigate = useNavigate()

    const gotoHome = () => {
        navigate(allPaths?.HOME)
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <section className='mainheader'>
                    <div className='Top-header'>
                        <div className='header_left'>
                            <img onClick={gotoHome} src={diSearchLogo} alt='' className='logomain' />
                        </div>
                        <div className='right_side'>
                            <div className='name_space biling-container' style={{ marginLeft: -10 }}>
                                <Button className={`header-btn active-plan`} onClick={() => navigate(allPaths?.LICENSE)}>License</Button>
                                <Button className='header-btn active-plan' style={{ marginLeft: 10 }} onClick={() => window.open(REDIRECT_URL, '_blank')}>Log In</Button>
                            </div>
                        </div>
                    </div>
                </section>
            </AppBar>
        </Box >
    )
}

export default Sidenav