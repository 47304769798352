import { Button, Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom'
import { Billing, License } from '../Screens'
import { Sidenav } from '../Components'
import allPaths from './paths'

const Page404 = (props) => {
    const navigate = useNavigate()
    return (
        <Result
            status='404'
            title='404'
            subTitle='Sorry, the page you visited does not exist.'
            extra={<Button
                type='primary'
                className='form-button'
                onClick={() => navigate(allPaths?.HOME)}
            >Back Home</Button>}
        />
    )
}


const WrapComponent = ({ Component, ...props }) => {
    const [toggleHeader, setToggleHeader] = useState(false)

    return (
        <div className='main_container'>
            <section className='main-screen'>
                <Sidenav toggleHeader={toggleHeader} setToggleHeader={setToggleHeader} />
                <div className='main-section'>
                    <div className='main-container'>
                        <Component {...props} />
                    </div>
                </div>
            </section>
        </div>
    )
}

const AllRoutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={allPaths?.HOME} element={<WrapComponent Component={Billing} />} />
                <Route path={allPaths?.LICENSE} element={<WrapComponent Component={License} />} />
                <Route path='/:page404' element={<Page404 />} />
            </Routes>
        </BrowserRouter>
    )
}

export {
    AllRoutes,
    Page404
}

