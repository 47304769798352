import 'antd/dist/antd.min.css'
import React from 'react'
import { AllRoutes } from './Config/routes'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

const App = () => {
  return (
    <div>
      <AllRoutes />
    </div>
  )
}


export default App