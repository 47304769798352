import React, { useEffect, useRef, useState } from 'react'
import './license.css'

const License = () => {
    const topRef = useRef(null)

    useEffect(() => {
        topRef.current.scrollIntoView()
    }, [])
    return (
        <div ref={topRef} className="main-pages privacy-policy">
            <section style={{ width: '75%', margin: '0 auto' }}>
                <h1 style={{ fontSize: 25, fontWeight: 500, marginBottom: 6, color: '#3a4d77' }}>Privacy And Compliance</h1>

                <div className='main-blogs-privacy'>
                    <span style={{ display: 'flex' }}>Revised as of : <h3 style={{ fontSize: 18, marginBottom: 6, marginLeft: 2 }}> 2023-12-31</h3> </span>
                    <p>Learn what's new in version 1.0.2</p>
                    <br />
                    <p style={{ fontSize: 25, fontWeight: 500, marginBottom: 6, color: '#3a4d77' }}>CONFIDENTIALITY</p>
                    <p className='heading-main-docs'>Confidential Information:</p>
                    <p><span>DI and Customer may disclose and/or exchange information, including Confidential Information, as necessary for purposes of this Agreement. “Confidential Information” means (i) any non-public business, technical, personnel and/or financial information of either party, and (ii) any other information that the disclosing party (“Discloser”) desires to protect against unrestricted disclosure by the receiving party (“Recipient”) and that: (a) if disclosed in tangible or electronic form, is marked as confidential or (b) if disclosed orally or visually, is designated as confidential at the time of disclosure. All Licensed Software shall be Confidential Information of DI. The Recipient shall maintain the confidentiality of Discloser’s Confidential Information with at least the same degree of care that it uses to protect its own Confidential Information, and in no event less than a reasonable degree of care. The Recipient shall not use in any way for its own account or the account of any third party, nor disclose to any third party, any of Discloser’s Confidential Information, except as authorized under this Agreement.</span> </p>
                    <p className='heading-main-docs'>Exceptions:</p>
                    <p><span>Confidential Information does not include any information that is: (i) rightfully in the public domain without any breach by the Recipient, (ii) rightfully received by Recipient from a third party without any obligation of confidentiality, (iii) was already rightfully known by Recipient without any obligation of confidentiality prior to its receipt from Discloser, (iv) independently developed by Recipient without use of or reference to Discloser’s Confidential Information, (v) made generally available to third parties by Discloser without restrictions on disclosure, or (vi) required (on advice of counsel) to be disclosed under applicable laws or governmental rules or regulations or judicial or administrative ruling or order, provided that Recipient gives reasonable prior notice to Discloser of such duty to disclose.</span> </p>
                    <p className='heading-main-docs'>Disclosure to Personnel:</p>
                    <p><span>The Recipient agrees to restrict dissemination of Confidential Information to those personnel, representatives or agents of the Recipient who require access thereto to carry out the purpose of the disclosures described above and then only provided that such personnel, representatives or agents have a clear understanding of and are required by the Recipient to abide by the obligations to maintain the confidentiality of the Confidential Information in accordance with the terms hereof. Recipient shall be liable to the Discloser for any breach of this Agreement by its personnel, representatives, or agents.</span> </p>
                    <p className='heading-main-docs'>Other Provisions:</p>
                    <p><span>All Confidential Information shall remain the sole and exclusive property of the Discloser. All Confidential Information supplied to either party hereunder (as well as all copies and reproductions thereof) shall be returned or disposed of by the Recipient pursuant to the written instructions of the Discloser. This section shall survive the termination of this Agreement for a period of five (5) years thereafter.</span> </p>
                    {/*  */}
                    <br />
                    <p style={{ fontSize: 25, fontWeight: 500, marginBottom: 6, color: '#3a4d77' }}>PROTECTION OF CUSTOMER DATA</p>
                    <p><span>DI will maintain administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of Customer Data in its possession. Those safeguards will include, but will not be limited to, measures for preventing access, use, modification or disclosure of Customer Data by DI personnel, except (a) as necessary to provide Maintenance and Product Support; (b) as necessary to provide Professional Services in accordance with a Work Order, as applicable; (c) as compelled by law in accordance with Section 5 (Confidentiality), or (d) as expressly permitted in writing by Customer.</span> </p>
                    <br /><p><span>Notwithstanding anything to the contrary in this Agreement or any other agreements signed between the parties, DI’s obligations extend only to those systems, networks, network devices, facilities and components over which DI exercises control. For clarity, Section 7.1 does not apply to: (i) Customer Data hosted on Customer’s servers; (ii) Customer Data in Customer’s virtual private network (VPN) or a third party network; or (iii) any Customer Data processed by Customer or its Users in violation of this Agreement.</span> </p>
                    <br /><p><span>Customer acknowledges that any electronic Protected Health Information (ePHI) or Personally Identifiable Information (PII) disclosed to DI as part of Customer Data is incidental to the Licensed Software under this Agreement and that DI does not review or analyze the content of Customer Data in the ordinary course of operating the Licensed Software. Customer agrees that it shall be solely responsible for complying with any obligations under any government regulation that requires any review or analysis of such content.</span> </p>
                    <br /><p><span>For purposes of this Agreement, “Customer Data” means any electronic data uploaded by or for Customer and Customer’s agents, employees and contractors that is processed through the Licensed Software, excluding DI data. For the avoidance of doubt, Customer is the data controller.</span> </p>
                  <br/>  <p style={{ fontSize: 25, fontWeight: 500, marginBottom: 6, color: '#3a4d77' }}>Conclusion</p></div>
                <p><span>Data Imagineers (DI) is dedicated to protecting your data privacy and complying with applicable laws and regulations. By using our Service, you agree to the terms outlined in this document.</span> </p>
            </section>
        </div>
    )
}

export default License