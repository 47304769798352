import React, { useState, useEffect } from 'react'
import { REDIRECT_URL, diSearchLogo, subscriptionData } from '../../utils/constants'
import { Button } from 'antd'
import './Billing.css'

const Billing = (props) => {
    const { active } = props
    const [year, setYear] = useState(false)

    useEffect(() => {
        const parentScroll = document.querySelector('.main-section');
        if (parentScroll) {
            parentScroll.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            parentScroll.style = active == 5 ? 'overflow-y: hidden !important;' : 'overflow-y: auto !important;';
        }

    }, [active])


    const yearly = () => {
        setYear(true)
    }

    const month = () => {
        setYear(false)
    }

    return (
        <>
            <div className="pricing_main_div">
                <div className="pricing_inner_div">
                    <div style={{ justifyContent: 'center' }} className='header_left'>
                        <img src={diSearchLogo} alt='' className='billinglogo' />
                    </div>
                    <>
                        <div className='pricing_heading_div'>
                            <div>
                                <p style={{ fontSize: '16px', color: '#3a4d77', marginTop: 40, marginBottom: 4 }}>Choose the best plan for your business</p>
                                <div className='biling-container'>
                                    <Button className={`${!year ? 'monthly-btn active-plan' : 'monthly-btn'}`} onClick={month}>MONTHLY</Button>
                                    <Button className={`${year ? 'yearly-btn active-plan' : 'yearly-btn'}`} onClick={yearly}>ANNUAL</Button>
                                </div>
                                <br />
                            </div>
                        </div>
                        <div className={"pricing_card_div"}>
                            {subscriptionData?.map((v, i) => {
                                return (
                                    <div className="Pricing_box_card" key={i}>
                                        <div className="main_div_example_card">
                                            <div className="inner_example_card">
                                            </div>
                                            <div className='main_inner_example_card'>
                                                <div className="pricing_plan_section">
                                                    <div className='pricing_plan_section_top'>
                                                        <div className='entry_heading fonts-heading'><h2>{v?.package}</h2></div>
                                                        <div className="ammouont_heading">
                                                            <span className='ammount_dollar price_heading'><span>{`${!year ? `${v?.priceMonthly}` : `${v?.priceYearly}`}`}</span></span>
                                                            <span className='ammount_dollar' style={{ marginTop: 12 }}><span>/month</span></span>
                                                        </div>
                                                    </div>
                                                    <div className="pricing_plan">
                                                        <div className="pricing_plan_inner_section  ">
                                                            <span className='pricing_plan_inner_section_content'>
                                                                (<>{v?.type}</>)
                                                            </span>
                                                        </div>
                                                        <div className="pricing_plan_inner_section  ">
                                                            <span className='pricing_plan_inner_section_content'>
                                                                <>{v?.license}</> Licenses
                                                            </span>
                                                        </div>
                                                        <div className="pricing_plan_inner_section  ">
                                                            <span className='pricing_plan_inner_section_content'>
                                                                <>{v?.guests}</> Guests
                                                            </span>
                                                        </div>
                                                        <div className="pricing_plan_inner_section  ">
                                                            <span className='pricing_plan_inner_section_content'>
                                                                <>{v?.storage}</> Storage
                                                            </span>
                                                        </div>
                                                        <div className="pricing_plan_inner_section  ">
                                                            <span className='pricing_plan_inner_section_content'>
                                                                <>{v?.credits}</> {v?.creditsValue}
                                                            </span>
                                                        </div>
                                                        <div className="pricing_plan_inner_section  ">
                                                            <span className='pricing_plan_inner_section_content'>
                                                                <>{v?.support}</> {v?.supportValue}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div className="get_started_btn" onClick={() => window.open(REDIRECT_URL, '_blank')}>
                                                            <button>Get Started</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                </div>
            </div>
        </>

    )
}

export default Billing