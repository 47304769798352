import allPaths from '../Config/paths'
const bgColor = '#0adc00'
const apiUrl = `http://localhost:8080`

const PLAN_FILTER = [
    'Valley',
    'Ascent',
    'Ridge',
    'Free trial',
    'Cancel subscription',
    'Expired subscription'
]
const diSearchBlueColor = '#1c7dff'
const googleClientId = '779369970183-h1v2arak0lsmj9u3fdjriup35u6cbjdp.apps.googleusercontent.com'
const googleApiKey = 'AIzaSyANsB-YR2BBQjPxb08SDfXPTy5-bdCohq8'
const clientSecret = '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'

const diSearchLogo = `https://storage.googleapis.com/di_search_test_bucket/logo/diSearch%20logo%2002-01.svg`
const diSearchLogo2 = `https://storage.googleapis.com/di_search_test_bucket/logo/white%20logo%20diSearch%20full-01.svg`
const diSearchLogo3 = `https://storage.googleapis.com/di_search_test_bucket/logo/diSearch%20logo%2002-01-02.svg`
const diSearchLogo4 = `https://storage.googleapis.com/di_search_test_bucket/logo/white%20logo%20diSearch%20half-02.svg`

const captchClientKey = '6LdhmzUpAAAAAJCDRxJ3e8w06uMY23iHaWG0W2y0'

const subscriptionData = [
    {
        card: 1,
        package: 'Valley',
        type: 'for Single users',
        priceMonthly: 1500,
        priceYearly: 1375,
        duration: 'month',
        license: '5',
        guests: '0',
        storage: '10GB',
        credits: '2000',
        creditsValue: 'GSC/mo. Governed Search',
        support: 'Support',
        supportValue: 'Available'
    },
    {
        card: 2,
        package: 'Ascent',
        type: 'for Teams',
        priceMonthly: 2500,
        priceYearly: 2250,
        duration: 'month',
        license: '10',
        guests: '10',
        storage: '20GB',
        credits: '4000',
        creditsValue: 'GSC/mo. Governed Search',
        support: 'Priority Support',
        supportValue: 'Available'
    },
    {
        card: 3,
        package: 'Ridge',
        type: 'for Teams',
        priceMonthly: 4200,
        priceYearly: 3700,
        duration: 'month',
        license: '20',
        guests: '30',
        storage: '30GB',
        credits: '6000',
        creditsValue: 'GSC/mo. Governed Search',
        support: 'Priority Support',
        supportValue: 'Available'
    }
]

const REDIRECT_URL = `https://saas.disearch.ai/login`

export {
    bgColor,
    apiUrl,
    allPaths,
    PLAN_FILTER,
    diSearchBlueColor,
    googleClientId,
    googleApiKey,
    clientSecret,
    diSearchLogo,
    diSearchLogo2,
    diSearchLogo3,
    diSearchLogo4,
    captchClientKey,
    subscriptionData,
    REDIRECT_URL
}

